import '../../css/detail-ukm/infoLengkapUKM.css';
import ReactHtmlParser from 'react-html-parser'; 
import Fade from 'react-reveal/Fade';

const InfoLengkapUKM = (props) => {
    return(<>
        <div className="ukm-info">
            <div>
                <div className="info-circle"></div>
                <div className="ml-4">
                    <h5 className="heading-app"> VISI DAN MISI </h5>
                    <article>
                        { ReactHtmlParser(props.ukm.vision) }
                    </article>
                </div>
            </div>
            <div className="my-5">
                <div className="info-circle"></div>
                <div className="ml-4">
                    <h5 className="heading-app"> BENEFIT </h5>
                    <article>
                    { ReactHtmlParser(props.ukm.benefit) }
                    </article>
                </div>
            </div>
            { (props.ukm.staffs).length > 0 && 
                <div>  
                    <div className="info-circle"></div>
                    <div className="ml-4">
                        <h5 className="heading-app"> STRUKTUR KEANGGOTAAN </h5>
                        <article>
                            {
                                props.ukm.staffs.map((staff, i) => {
                                    return(
                                        <div>
                                            <h4><b>{ staff.position }</b></h4>
                                            <div>{ staff.mahasiswa.name }</div>
                                        </div>)
                                })
                            }
                        </article>
                    </div>
                </div>
            }
            <div className="mt-5">
                <div className="info-circle "></div>
                <div className="ml-4">
                    <h5 className="heading-app"> JADWAL </h5>
                    
                </div>
            </div>
        </div>
        <div className="ukm-end ml-4">
                <article>
                    { ReactHtmlParser(props.ukm.schedule) }
                </article>
        </div>
    </>);
}

export default InfoLengkapUKM;