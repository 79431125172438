import FormPendaftaran from '../components/form-pendaftaran/FormPendaftaran.js';
import DeskripsiUKM from '../components/detail-ukm/DeskripsiUKM.js';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { server } from '../helpers/server.js';
import PreLoader from '../components/layouts/PreLoader.js';

const PendaftaranUKM = () => {
    const { id, name } = useParams();
    const [ukm, setUKM] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch(`${server}api/v1/ukm/detail/${name}?token=${process.env.REACT_APP_API_TOKEN}`)
        .then(res => res.json())
        .then(data => { 
            setUKM(data.data);
            setLoading(false); 
        }); 
    }, []);

    if(loading) 
        return <PreLoader />

    return (<>
        <div className="container my-4">
            <div className="">
                <DeskripsiUKM ukm={ukm} action={false} />
            </div>
            <div className="my-5">
                <FormPendaftaran ukm={ukm}/>
            </div>
        </div>
    </>);
}

export default PendaftaranUKM;