import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import DetailUKM from './pages/DetailUKM.js';
import Footer from './components/layouts/Footer.js';
import Landing from './pages/Landing.js';
import Navbar from './components/layouts/NavigationBar.js';
import KategoriUKM from './pages/KategoriUKM.js';
import { useRef, useState } from 'react';
import ScrollToTop from './helpers/ScrollToTop.js';
import PendaftaranUKM from './pages/PendaftaranUKM.js';
import SaranUKM from './pages/SaranUKM.js';
import SearchView from './pages/SearchView.js'

function App() {
  const footerRef = useRef(null);
  const [triggerKategori, setTriggerKategori] = useState(false);

  const scrollToFooter = () => footerRef.current.scrollIntoView({ behavior: 'smooth' })

  return (<>
    <Router>
      <Navbar scrollToFooter={scrollToFooter} triggerKategori={triggerKategori} setTriggerKategori={setTriggerKategori} />
      <ScrollToTop>
        <Switch>
          <Route exact path="/">
            <Landing />
          </Route>
          <Route path="/hasilpencarian">
            <SearchView triggerKategori={triggerKategori}/>
          </Route>
          <Route exact path="/ukm/:id/:name">
            <DetailUKM />
          </Route>
          <Route path="/kategori/:id/:name">
            <KategoriUKM triggerKategori={triggerKategori} setTriggerKategori={setTriggerKategori}/>
          </Route> 
          <Route exact path="/ukm/:id/:name/daftar">
            <PendaftaranUKM />
          </Route> 
          <Route exact path="/SaranUKM">
            <SaranUKM />
          </Route>

        
        </Switch>
      </ScrollToTop>
      <div ref={footerRef}>
        <Footer />
      </div>
    </Router>
  </>);
}

export default App;
