import { useState } from "react";
import "../../css/detail-ukm/PertanyaanUKM.css";
import { server } from '../../helpers/server';
import React from 'react';
import { Redirect, useParams} from 'react-router';
import SuccessToast from '../layouts/SuccessToast';
import Swal from 'sweetalert2';  


const DaftarUKM = (props) => {
    const [showSuccess, setShowSuccess] = useState(false);
    const [error, setError] = useState();

    const [inputs, setInputs] = useState({});

    var nomor_hpField = document.querySelector("input[name=nomor_hp]");
    if(nomor_hpField){
        nomor_hpField.addEventListener("invalid", function(){
            this.setCustomValidity('');
            if (!this.validity.valid) {
                this.setCustomValidity('Nomor hp harus diawali dengan +628 atau 08 minimal 8 digit');  
            }
        })
    };

    var nrpField = document.querySelector("input[name=nrp]");
    if(nrpField){
        nrpField.addEventListener("invalid", function(){
            this.setCustomValidity('');
            if (!this.validity.valid) {
                this.setCustomValidity('NRP berisi angka yang berjumlah 10 digit');  
            }
        })
    };

    const [formSubmited, setFormSubmited] = useState(false);

    const handleInputs = (e) => setInputs({...inputs, [e.target.name]: e.target.value });

	const submitForm = (e) => {
		e.preventDefault();
		fetch(`${server}api/v1/ukm/questions/${props.ukmId}?token=${process.env.REACT_APP_API_TOKEN}&nrp=${inputs.nrp}&name=${inputs.name}&phone_number=${inputs.phone_number}&value=${inputs.value}&answer=-`, {
			method: 'POST',
		})
		.then(res => res.json())
		.then(data => {
			if(data.type === 'success') {
                document.getElementById("pertanyaanUKMForm").reset();
                setError(null);
                Swal.fire({
                    icon: 'success',
                    title: 'Pertanyaan Berhasil di tambahkan',
                    showConfirmButton: false,
                    timer: 1500
                });
            } else {
                setError(data.message)
            }
		})
	}

    if(formSubmited) {
        let urlName = (props.ukm.name).toLowerCase();
        urlName = urlName.replaceAll(' ', '-');
        return <Redirect to={`/ukm/${props.ukm.id}/${urlName}`} />
    }

    return (<>
    <SuccessToast text={'Pendaftaran berhasil'} showSuccess={showSuccess} setShowSuccess={setShowSuccess}/>
        <div className="container py-5">
			<form onSubmit={submitForm} id="pertanyaanUKMForm" className="row d-flex flex-column flex-lg-row align-items-center">
				<h1 className="text-center col-12 col-lg-4"><b>MASIH BINGUNG? YUK AJUKAN PERTAYAAN MU!</b></h1>
				<div className="form col-12 col-lg-8 px-5 d-flex flex-column">
					<div>
						<label htmlFor="nrpInput" className="form-label">NRP</label>
						<input id="nrpInput" type="text" name="nrp" className="form-control" pattern="[0-9]{10}" onChange={handleInputs} required/>
					</div>
					<div>
						<label htmlFor="nameInput" className="form-label">Nama</label>
						<input id="nameInput" type="text" name="name" className="form-control" onChange={handleInputs} required/>
					</div>
					<div>
						<label htmlFor="emailInput" className="form-label">Email</label>
						<input id="emailInput" type="email" name="email" className="form-control" onChange={handleInputs} required/>
					</div>
					<div>
						<label htmlFor="whatsAppInput" className="form-label">No Whatsapp</label>
						<input id="whatsAppInput" type="text" name="nomor_hp" className="form-control" pattern="(\+62|62|0)8[1-9][0-9]{6,11}" onChange={handleInputs} required/>
					</div>
					<div>
						<label htmlFor="questionInput" className="form-label">Pertanyaan</label>
						<textarea id="questionInput" className="form-control" name="value" onChange={handleInputs} required></textarea>
					</div>
                    <button type="submit" className="btn btn-app mt-5 align-self-end">Tanyakan</button>
				</div>
			</form>
		</div>
   </>);
}
export default DaftarUKM;
