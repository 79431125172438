import { Link } from "react-router-dom"
import "../../css/detail-ukm/DaftarUKM.css"
import slugify from 'slugify';

const DaftarUKM = (props) => {
    return (
        <div className="daftar">
            <div className="container">
                <h5>
                    <b>
                        MARI KEMBANGKAN BAKATMU BERSAMA KAMI
                    </b>
                </h5>
                <Link to={`/ukm/${props.ukm.id}/${slugify(props.ukm.name)}/daftar`}><button className="btn btn-app mt-3">AYO MENDAFTAR</button></Link>
            </div>
        </div>
    )
}
export default DaftarUKM;
