import { Link } from 'react-router-dom';
import '../../css/layouts/Footer.css';

const Footer = () => {
    return(<>
        <div className="footer-body container-fluid">
            <div className="row py-4 px-1 px-md-5 py-md-5">
                <div className="col-12 col-md-6 col-lg-4 px-2 px-md-5">
                    <h4 className="mb-4"><b> TENTANG KAMI </b></h4>
                    <p>
                        <b>SI-UKM</b> merupakan website yang dapat 
                        membantu mahasiswa mendapatkan 
                        informasi mengenai seluruh unit kegiatan 
                        mahasiswa yang ada di Politeknik Elektronika Negeri Surabaya dan 
                        memudahkan mahasiswa untuk melakukan pendaftaran pada UKM yang diminati.
                    </p>
                    <p>
                        Website <b>SI-UKM</b> ini dikembangkan oleh Tim New Normal mahasiswa Teknik Informatika PENS 2019.
                    </p>
                </div>
                <div className="col-12 col-md-6 col-lg-4 px-2 px-md-5">
                    <h4 className="mb-4"><b> Kontak LMB </b></h4>
                    <p>Anda dapat mengubungi LMB Pens pada informasi di bawah ini</p>
                    <ul className="contact-list icons">
                        <li>
                            {/* <img src={`${imagesPath}instagram-icon.svg`} className="mr-2"></img> */}
                            <b>JL. Raya ITS, Keputih, Sukolilo, Surabaya</b>
                        </li>
                        <li>
                            {/* <img src={`${imagesPath}instagram-icon.svg`} className="mr-2"></img> */}
                            <b>081230210480</b>
                        </li>
                        <li>
                            <img src={`https://i.postimg.cc/tJTtxY4p/instagram-icon.png`} className="mr-2"></img>
                            <b>@LMBOfficial</b>
                        </li>
                        <li>
                            <img src={`https://i.postimg.cc/SNnfQ2PG/twitter-icon.png`} className="mr-2"></img>
                            <b>@LMB_TWT</b>
                        </li>
                        <li>
                            <img src={`https://i.postimg.cc/wMG5cg1J/line-icon.png`} className="mr-2"></img>
                            <b>LMB_LINE</b>
                        </li>
                    </ul>
                </div>
                <div className="col-12 col-md-12 col-lg-4 mt-5 mt-lg-0 d-flex justify-content-center align-items-center">
                    <Link to="/"><img src="https://i.postimg.cc/DZJXsZJK/LOGO.png" className="footer-logo"></img></Link>
                </div>
            </div>
            <div className="footer-divider row">
            </div>
            <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center px-2 px-md-5 py-2">
                <div className="mb-5 mb-sm-0 d-flex flex-column align-items-center align-items-md-start">
                    <b>Hubungi Tim Kami</b>
                    <div className="icons mt-1">
                        <a href="https://www.instagram.com/madewithlove.dev/?hl=en" target="_blank"><img src={`https://i.postimg.cc/tJTtxY4p/instagram-icon.png`}></img></a>
                        <img src={`https://i.postimg.cc/NMbkF1Yd/facebook-icon.png`} className="mx-4"></img>
                    </div>
                </div>
                <b>Made With <span style={{color: 'red'}}>❤</span> ️By  Tim New Normal</b>
            </div>
        </div>
    </>);
}

export default Footer;