import '../../css/layouts/SuccessToast.css';
import { Toast } from 'react-bootstrap';

const SuccessToast = (props) => {
    return(
        <div className="d-flex justify-content-center align-items-center">
            <Toast onClose={() => props.setShowSuccess(false)} show={props.showSuccess} delay={3000} autohide
                style={{
                position: 'fixed',
                zIndex: 99999,
                backgroundColor: "white",
                width: "300px"
                }}>
                <Toast.Body className="d-flex flex-column align-items-center">
                <div className="check_mark" style={{ position: "relative", right: "2px" }}>
                    <div className="sa-icon sa-success animate">
                    <span className="sa-line sa-tip animateSuccessTip"></span>
                    <span className="sa-line sa-long animateSuccessLong"></span>
                    <div className="sa-placeholder"></div>
                    <div className="sa-fix"></div>
                    </div>
                </div>
                <h6 className="text-center"><b>{props.text}</b></h6>
                </Toast.Body>
            </Toast>
      </div>
    )
}

export default SuccessToast;