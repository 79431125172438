import React from 'react';
import { Link } from 'react-router-dom';
import slugify from 'slugify';
import { server } from '../../helpers/server';

const UKMHasilRekomendasi = (props) => {
    // const [showButton, setShowButton] = useState({});
    const hasilRekomendasi = React.createRef();

    const scrollLeft = () => {
        hasilRekomendasi.current.scrollTo({
            left: hasilRekomendasi.current.scrollLeft - (hasilRekomendasi.current.offsetWidth / 2),
            behavior: 'smooth'
        });
    }

    const scrollRight = () => {
        hasilRekomendasi.current.scrollTo({
            left: hasilRekomendasi.current.scrollLeft + (hasilRekomendasi.current.offsetWidth / 2),
            behavior: 'smooth'
          });
    }

    return(<>
    <div style={{ position: 'relative' }}>
        <button className="btn-kiri btn" onClick={() => scrollLeft()}><img className="btn-control" src="https://i.postimg.cc/DZmNTp8c/button-left-header.png"></img></button>
        <button className="btn-kanan btn" onClick={() => scrollRight()}><img className="btn-control" src="https://i.postimg.cc/fbzFHRsp/button-right-header.png"></img></button>
            <div className="container d-flex" ref={hasilRekomendasi} style={{ overflowX: 'hidden', overflowY: 'hidden' }}>
                {
                    props.recommendedUKM.recoms.map((recom, i) => {
                        return(
                        <div role="recommendedUKM" key={i} className="ukm-rekomendasi-hasil my-3 mx-4 my-md-0">
                            <img role="recommendedUKMImage" src={`${server}${props.recommendedUKM.data[recom.ukm_id].logo}`} 
                            className="card-img-top img-rekomendasi" alt="icon" />
                            <Link className={"rekomendasi-link py-4 "} 
                            role="recommendedUKMLink"
                            to={`/ukm/${props.recommendedUKM.data[recom.ukm_id].id}/${slugify(props.recommendedUKM.data[recom.ukm_id].name)}`}>
                                <div>Read more</div>
                            </Link>
                        </div>   
                        )
                    })
                }
            </div>
            <div className="nav-bottom container">
                <button className="btn-kiri-res btn" onClick={() => scrollLeft()}><img className="btn-control" src="https://i.postimg.cc/DZmNTp8c/button-left-header.png"></img></button>
                <button className="btn-kanan-res btn" onClick={() => scrollRight()}><img className="btn-control" src="https://i.postimg.cc/fbzFHRsp/button-right-header.png"></img></button>
            </div>
    </div>
    </>)
}

export default UKMHasilRekomendasi;