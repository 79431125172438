import '../css/kategori-ukm/KategoriUKM.css';
import UKMDiKategori from '../components/kategori-ukm/UKMDiKategori.js';
import KategoriHeader from '../components/kategori-ukm/KategoriHeader.js';
import PreLoader from '../components/layouts/PreLoader';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { server } from '../helpers/server';
import { Link } from 'react-router-dom';
import SaranBanner from '../components/layouts/SaranBanner';

const KategoriUKM = ({ triggerKategori, setTriggerKategori }) => {
    const { id, name } = useParams();
    const [ukms, setUkms] = useState([]);
    const [loading, setLoading] = useState(true);

    
    useEffect(() => {
        setLoading(true);
        fetch(`${server}api/v1/categories?token=$2y$10$5we5Z7yY.qCxKBcDF91Wv.2W1XBaFmfAUhTQKxSsgk6kWSgIiVigW&id=${id}`)
        .then(res => res.json())
        .then(data =>{
            setUkms(data.data);
            setLoading(false);
        });
    }, [triggerKategori]);

    if(loading) 
        return <PreLoader />

    if(ukms.length === 0) 
        return (<>
            <KategoriHeader name={name} /> 
            <div className="d-flex flex-column align-items-center justify-content-center ukm-not-available px-4">
                <h1 className="text-muted text-center">Belum ada UKM yang terdaftar dalam kategori ini</h1>
                <h2 className="text-muted"> :( </h2>
                <Link to="/"><button className="btn btn-app mt-5">Yuk, Eksplor lagi</button></Link>
            </div>
        </>)

    return(<>
        <div className="content-margin-nalar"> 
            <KategoriHeader name={name} /> 
            <UKMDiKategori ukms={ukms} />
        </div>
        <div className="my-5">
            <SaranBanner />
        </div>
    </>);
}

export default KategoriUKM;
