import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../../css/landing/KategoriLanding.css';
import { server } from '../../helpers/server';
import Spinner from '../layouts/Spinner.js';

const KategoriLanding = (props) => {
  const [kategoriUKM, setKategoriUKM] = useState([]);
    const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${server}api/v1/categories?token=${process.env.REACT_APP_API_TOKEN}`)
    .then(res => res.json())
    .then(resJson => { 
        setKategoriUKM(resJson.data);
        setLoading(false); 
    });
  }, []);

  if(loading) 
    return <Spinner />

  return (<>
  <div>
    <div className="container-tiga ">
        { 
            kategoriUKM.map((kategori, index) => {
                return(
                    <Link to={`/kategori/${kategori.id}/${kategori.name}`}>
                        <div className="kategoriy" key={index}>
                            <h5 className="title-kategori text-center">{ (kategori.name).toUpperCase() }</h5>
                            <div className="card kategori-card">
                                <img src={`${server}${kategori.logo}`} className="card-img-top" alt="icon" />
                            </div>
                        </div>
                    </Link>
                );
            })
        }
    </div>
</div>

 </> );
}

export default KategoriLanding;
