const KategoriHeader = (props) => {
    return(<>
    <div>
        <span className="d-block kategori-block">
          <p className="kategori-header">KATEGORI {props.name.toUpperCase()} </p>
        </span>
    </div>
    </>);
}

export default KategoriHeader;