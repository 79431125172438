import { useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router';
import '../../css/form-pendaftaran/formPendaftaran.css';
import { server } from '../../helpers/server';
import SuccessToast from '../layouts/SuccessToast';
import React from 'react';
import Swal from 'sweetalert2';  

const FormPendaftaran = () => {
    const { id } = useParams();
    const history = useHistory();
    const [showSuccess, setShowSuccess] = useState(false);
    const [error, setError] = useState();

    const [inputs, setInputs] = useState({
        nrp: '',
        email: '',
        nama: '',
        kelas: '',
        jurusan: '',
        alamat: '',
        nomor_hp: '',
        alasan: ''
    });


    var nomor_hpField = document.querySelector("input[name=nomor_hp]");
    if(nomor_hpField){
        nomor_hpField.addEventListener("invalid", function(){
            this.setCustomValidity('');
            if (!this.validity.valid) {
                this.setCustomValidity('Nomor hp harus diawali dengan +628 atau 08 minimal 8 digit');  
            }
        })
    };

    var nrpField = document.querySelector("input[name=nrp]");
    if(nrpField){
        nrpField.addEventListener("invalid", function(){
            this.setCustomValidity('');
            if (!this.validity.valid) {
                this.setCustomValidity('NRP berisi angka yang berjumlah 10 digit');  
            }
        })
    };
    
    const handleInput = (e) => {
        const key = e.target.name;
        const value = e.target.value;
        setInputs({...inputs, [key]: value });
        // console.log(validation(value));
        // setError(validation(value));

    }

    const submitForm = (e) => {
        e.preventDefault();

        const { nrp, email, nama, kelas, jurusan, alamat, nomor_hp, alasan } = inputs;
        fetch(`${server}api/v1/ukm/regis/${id}?token=${process.env.REACT_APP_API_TOKEN}&nrp=${nrp}&name=${nama}&phone_number=${nomor_hp}&major=${jurusan}&address=${alamat}&email=${email}&reason=${alasan}`, {
            method: "POST",
            headers: {
                "Accept": "application/json"
            }
        })
        .then(res => res.json())
        .then(data => {
            if(data.type === 'success') { 
                document.getElementById("daftarUKMForm").reset();
                setError(null);
				// berhasil tanya
				// tampilin popup
                Swal.fire({
                    icon: 'success',
                    title: 'Pendaftaran Berhasil',
                    showConfirmButton: false,
                    timer: 1500
                });
            } else {
                setError(data.message)
            }
        });
    }

    return (<>
        <SuccessToast text={'Pendaftaran berhasil'} showSuccess={showSuccess} setShowSuccess={setShowSuccess}/>
        <div className="formulirPendaftaran">
            <h4 className="judul mb-5">FORM REGISTRASI UKM</h4>
            <form className="form-input" id="daftarUKMForm" onSubmit={submitForm}>
                <div className="form">
                    <div className="d-flex flex-column flex-lg-row mx-5 my-3">
                        <label htmlFor="nrpInput" className="form-label col-4">NRP</label>
                        <input role="inputs" id="nrpInput" type="text" className="form-control fillForm" name="nrp" pattern="[0-9]{10}" onChange={handleInput} required></input>
                    </div>
                    <div className="d-flex flex-column flex-lg-row mx-5 my-3">
                        <label htmlFor="emailInput" className="form-label col-lg-4">Email</label>
                        <input role="inputs" id="emailInput" type="email" className="form-control fillForm" name="email" onChange={handleInput} required></input>
                    </div>
                    <div className="d-flex flex-column flex-lg-row mx-5 my-3">
                        <label htmlFor="nameInput" className="form-label col-lg-4">Nama Lengkap</label>
                        <input role="inputs" id="nameInput" type="text" className="form-control fillForm" name="nama" onChange={handleInput} required></input>
                    </div>
                    <div className="d-flex flex-column flex-lg-row mx-5 my-3">
                        <label htmlFor="classInput" className="form-label col-lg-4">Kelas</label>
                        <input role="inputs" id="classInput" type="text" className="form-control fillForm" name="kelas" onChange={handleInput} required></input>
                    </div>
                    <div className="d-flex flex-column flex-lg-row mx-5 my-3">
                        <label htmlFor="majorInput" className="form-label col-4">Jurusan</label>
                        <input role="inputs" id="majorInput" type="text" className="form-control fillForm" name="jurusan" onChange={handleInput} required></input>
                    </div>
                    <div className="d-flex flex-column flex-lg-row mx-5 my-3">
                        <label htmlFor="addressInput" className="form-label col-4">Alamat</label>
                        <input role="inputs" id="addressInput" type="text" className="form-control fillForm" name="alamat" onChange={handleInput} required></input>
                    </div>
                    <div className="d-flex flex-column flex-lg-row mx-5 my-3">
                        <label htmlFor="numberInput" className="form-label col-4">Nomor HP</label>
                        <input role="inputs" id="numberInput" type="text" className="form-control fillForm" name="nomor_hp" pattern="(\+62|62|0)8[1-9][0-9]{6,11}" onChange={handleInput} required>
                        </input>
                    </div>
                    <div className="d-flex flex-column flex-lg-row mx-5 my-3">
                        <label htmlFor="reasonInput" className="form-label col-lg-4">Alasan Mengikuti UKM</label>
                        <textarea id="reasonInput" type="reason" className="form-control fillForm" name="alasan" onChange={handleInput} required></textarea>
                    </div>
                </div>
                <p className="text-danger text-center">{error}</p>
                <div className="d-flex justify-content-between mx-5 mt-5">
                    <button className="btn btn-kembali d-none d-lg-inline" onClick={(e) => { e.preventDefault(); history.goBack(); } }>KEMBALI</button>
                    <button className="btn btn-app" type="submit">DAFTAR</button>
                </div>
            </form>
        </div>
    </>);

}

export default FormPendaftaran;



















































 