import "../../css/detail-ukm/FAQ.css";
import { Accordion, Card, Button } from 'react-bootstrap';

const FAQ = ({ ukm }) => {
    return (
        <div className="FAQ">
            <div className="blue-bg-faq"></div>
            <div className="container">
                <h1 className="faq">
                    FAQ
                </h1>
                <div className="info-FAQ">  
                <p>Sudahkah kamu menemukan pertanyaan yang sering diajukan? Kami membantumu menemukan jawabannya!</p>
                </div>
                <Accordion defaultActiveKey="0" style={{ marginTop: '100px' }}>
                    {
                        ukm.faqs.map((item, index) => {
                            return (<Card key={index}>
                                <Accordion.Toggle className="accordion-faq" as={Card.Header} eventKey={item.id}>
                                    { item.value }  
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={item.id}>
                                <Card.Body>{ item.answer } </Card.Body>
                                </Accordion.Collapse>
                            </Card>);
                        })
                    }
                {/* <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                        Dimana ruangan UKM Paduan Suara?   
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                    <Card.Body>Di gedung D3 HH201</Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="1">
                        Dimana ruangan UKM Paduan Suara?
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                    <Card.Body>Di gedung D3 HH201</Card.Body>
                    </Accordion.Collapse>
                </Card> */}
                </Accordion>    
            </div>
        </div>
    )                                                                                                    
}
export default FAQ