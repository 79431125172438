import { Link } from 'react-router-dom';
import '../../css/hasil-search/hasilSearch.css';
import { server } from '../../helpers/server';

const HasilSearch = (props) => {
    if(props.hasil?.data.length === 0)
        return(
            <div className="d-flex flex-column align-items-center justify-content-center ukm-not-available px-4">
                <h1 className="text-muted text-center">Tidak ada hasil yang ditemukan </h1>
                <h2 className="text-muted"> :( </h2>
                <Link to="/"><button className="btn btn-app mt-5">Yuk, Eksplor lagi</button></Link>
            </div>
        )

    return (<>
        <div className="container">
            <div className="result-box">
                {props.hasil?.data.map((data, i) => {
                        return(<Link className="listSearch mr-sm-2" to={`/ukm/${data.id}/${data.name}`}>
                                    <img src={`${server}${data.logo}`} className="card-img-top" alt="icon" />
                                    <div className="nama">{data.name}</div>
                               </Link>)
                    })
                }
            </div>
        </div>
    </>);
}

export default HasilSearch;