import '../../css/detail-ukm/DeskripsiUKM.css';
import ReactHtmlParser from 'react-html-parser'; 
import { server } from '../../helpers/server';
import { Link } from 'react-router-dom';
import slugify from 'slugify';

const DeskripsiUKM = (props) => {
    return (<>
        <div className="container-fluid">
            <div className="row">
                    <div className="col-lg-5 d-flex align-items-center">
                        <Link to={`/ukm/${props.ukm.id}/${slugify(props.ukm.name)}`}>
                            <img src={`${server}${props.ukm.logo}`} className="ukm-logo"></img>
                        </Link>
                    </div>
                <div className="col-lg-7 my-lg-auto my-1 my-lg-5 text-center text-lg-left">
                    <article>
                        <h4>
                            <Link className="heading-app" to={`/ukm/${props.ukm.id}/${slugify(props.ukm.name)}`}>{ props.ukm.name }</Link> 
                            </h4>
                        <p className="mt-5 mb-4"> 
                            { ReactHtmlParser(props.ukm.desc) }

                        </p>
                    </article>
                    { props.action &&
                        <div className="d-flex justify-content-center justify-content-lg-start my-5 my-lg-0">
                            <Link to={`/ukm/${props.ukm.id}/${slugify(props.ukm.name)}/daftar`}>
                                <button className="btn btn-app mr-2 mr-lg-4">DAFTAR</button>
                                </Link>
                            {/* <button className="btn btn-app">AJUKAN PERTANYAAN</button> */}
                        </div>
                    }
                </div>
            </div>
        </div>
    </>);
}

export default DeskripsiUKM;