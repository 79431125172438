import '../../css/landing/PapanPengumuman.css'
import { Carousel } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { server } from '../../helpers/server';

const PapanPengumuman = () => {
    const [index, setIndex] = useState(0);
    const imagesPath = '/images/landing-page/';
    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };
    const [images, setImages] = useState([]);

    useEffect(() => {
        fetch(`${server}api/v1/carousels?token=${process.env.REACT_APP_API_TOKEN}`)
        .then(res => res.json())
        .then(data => setImages(data));
    }, []);

    return (<>
        <Carousel activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item>
                <img
                    className="notification" 
                    src={`https://i.postimg.cc/gJWjL54D/up.png`}
                    alt="First slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="notification"
                    src={`https://i.postimg.cc/DzsmZFQ0/notif-2.png`}
                    alt="Second slide"
                />
            </Carousel.Item>
        </Carousel>
    </>);
}

export default PapanPengumuman;