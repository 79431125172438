import '../../css/kategori-ukm/KategoriUKM.css';
import { Link } from 'react-router-dom';
import { server } from '../../helpers/server';
import slugify from 'slugify';

const UKMDiKategori = (props) => {
  return (<>
    <div className="container-banyak ">
      {props.ukms.map((ukm, i) => {
        return (
          <div className="ukm-kat" >
            <div className="card seni-card">
              <img src={`${server}${ukm.logo}`} className="card-img-top card-kat-img" alt="icon" />
              <Link to={`/ukm/${ukm.id}/${slugify(ukm.name)}`}><div className="card-footer py-4">READ MORE</div></Link>
              <div className="card-footer-block py-4">
                <Link to={`/ukm/${ukm.id}/${slugify(ukm.name)}`}><h4 style={{ color: 'black' }}><b>{ukm.name}</b></h4></Link>
              </div>
            </div>
          </div>
        )
      })

      }
    </div>
  </>);
}

export default UKMDiKategori;