import '../../css/layouts/PreLoader.css';

const PreLoader = () => {
    return(
        <div className="loader-body d-flex align-items-center">
            <div className="loader">Loading...</div>
        </div>
    )
}

export default PreLoader;