import '../../css/detail-ukm/GaleriUKM.css';
import React from 'react';
import { Carousel } from 'react-bootstrap';
import { useState } from 'react';
import { server } from '../../helpers/server';

const GaleriUKM = (props) => {
    const imagesPath = '/images/detail-ukm/';
    const [index, setIndex] = useState(0);

    const hasilRekomendasi = React.createRef();

    const scrollLeft = () => {
        console.log(hasilRekomendasi.current.width);
        hasilRekomendasi.current.scrollTo({
            left: hasilRekomendasi.current.scrollLeft - (hasilRekomendasi.current.offsetWidth / 2),
            behavior: 'smooth'
          });
    }

    const scrollRight = () => {
        hasilRekomendasi.current.scrollTo({
            left: hasilRekomendasi.current.scrollLeft + (hasilRekomendasi.current.offsetWidth / 2),
            behavior: 'smooth'
          });
    }
    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };

    return (<>
        <div style={{ position: 'relative' }}>
            <button className="btn-kiri-gallery btn" onClick={() => scrollLeft()}><img className="btn-control" src="https://i.postimg.cc/DZmNTp8c/button-left-header.png"></img></button>
            <button className="btn-kanan-gallery btn" onClick={() => scrollRight()}><img className="btn-control" src="https://i.postimg.cc/fbzFHRsp/button-right-header.png"></img></button>
            <div className="container d-flex" ref={hasilRekomendasi} style={{ overflowX: 'hidden' }}>
                {
                    props.galleries.map((gallery, i) => {
                        return(
                            <img
                                className="d-block ukm-image" 
                                src={`${server}${gallery.name}`}
                                alt="First slide"
                            />
                        );
                    })
                }
            </div>
        </div>
    </>);
}

export default GaleriUKM;