import '../css/detail-ukm/DetailUKM.css';
import FAQ from '../components/detail-ukm/FAQ';
import SideBarUKM from '../components/detail-ukm/SideBarUKM.js';
import DeskripsiUKM from '../components/detail-ukm/DeskripsiUKM.js';
import KontakUKM from '../components/detail-ukm/KontakUKM.js';
import GaleriUKM from '../components/detail-ukm/GaleriUKM.js';
import InfoLengkapUKM from '../components/detail-ukm/InfoLengkapUKM.js';
import PrestasiUKM from '../components/detail-ukm/PrestasiUKM';
import { useEffect, useState } from 'react';
import DaftarUKM from '../components/detail-ukm/DaftarUKM';
import { useParams } from 'react-router';
import { server } from '../helpers/server';
import PreLoader from '../components/layouts/PreLoader';
import PertanyaanUKM from '../components/detail-ukm/PertanyaanUKM';

const DetailUKM = () => {
    const { id, name } = useParams();
    const [ukm, setUKM] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch(`${server}api/v1/ukm/detail/${name}?token=${process.env.REACT_APP_API_TOKEN}`)
        .then(res => res.json())
        .then(data => {
            setUKM(data.data);
            setLoading(false); 
        }); 
    }, []);

    if(loading) 
        return <PreLoader />

    return(<>
        <div className="container my-5 ukm-detail-page">
            {/* <div className="ukm-sidebar">   
                <SideBarUKM ukm={ukm}/>
            </div> */}
            <div className="">
                <DeskripsiUKM ukm={ukm} action={true} />
            </div>
            <div className="mt-lg-3 mb-lg-5">
                <KontakUKM ukm={ukm} />
            </div>
            { (ukm.galleries).length > 0 &&
                <div className="content-margin">
                    <GaleriUKM galleries={ukm.galleries}/>
                </div>
            }
            <div className="content-margin">
                <InfoLengkapUKM ukm={ukm} />
            </div>
            { ukm.achievements.length > 0 &&
                <div className="content-margin">
                    <PrestasiUKM achievements={ukm.achievements} galleries={ukm.galleries} />
                </div>
            }           
        </div>
        
        {
            (ukm.faqs).length > 0 &&
                <div classname="content-margin faq">
                    <FAQ ukm={ukm} />
                </div>
        }
        <div className="my-5 daftar">
            <DaftarUKM ukm={ukm} />  
        </div>

        <div className="my-5 daftar">
            <PertanyaanUKM ukmId={ukm.id}/>
        </div>

        
    </>);
}

export default DetailUKM;
