import '../../css/layouts/SaranBanner.css';
import { Link } from 'react-router-dom';

const SaranBanner = () => {
    return(<>
        <div className="saran-banner">
            <div className="d-flex flex-column container saran-text-content">
                <h3 className="text-saran"><b>KAMU BELUM MENEMUKAN UKM YANG SESUAI DENGAN MINAT MU?</b></h3>
                <h3 className="text-saran">YUK BERI KAMI SARAN!</h3>
                <Link to="/SaranUKM" className="align-self-end">
                    <button type="button" className="btn btn-app">BERI SARAN</button>
                </Link>
            </div>
            <div className="decor d-flex justify-content-between">
                <div className="decor-kiri"></div>
                <div className="decor-kanan"></div>
            </div>
        </div>
    </>)
}

export default SaranBanner;