import { useEffect, useState } from 'react';
import '../../css/landing/RekomendasiUKM.css';
import { server } from '../../helpers/server';
import RekomendasiModal from './RekomendasiModal.js';


const RekomendasiUKM = (props) => {
    
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch(`${server}api/v1/tag?token=${process.env.REACT_APP_API_TOKEN}`)
        .then(res => res.json())
        .then(data => { 
            setTags(data.tags);
            setLoading(false); 
        });
    }, []);

    const selectTags = (tag, index) => {
        let tempSelectedTags = selectedTags;
        let tempTags = tags;

        tempSelectedTags.push(tag);
        tempTags.splice(index, 1);

        setTags([...tempTags]);
        setSelectedTags([...tempSelectedTags]);
    }

    const unselectTags = (tag, index) => {
        let tempSelectedTags = selectedTags;
        let tempTags = tags;
        
        tempTags.push(tag);
        tempSelectedTags.splice(index, 1);

        setTags([...tempTags]);
        setSelectedTags([...tempSelectedTags]);
    }

    if(loading) return <div></div>
    
    return(<>
        <div className="rekomendasi-body d-flex flex-column align-items-center py-3 px-2 px-md-5 mx-3 mx-md-0">
            <h4 className="rekomendasi-heading text-center">Masih bingung memilih UKM ?</h4>
            <p className="rekomendasi-subheading text-center">Yuk, pilih kata kunci yang sesuai dengan keinginanmu!</p>
            <div>
                <div className="d-flex justify-content-center align-items-start row rekomendasi-tags-body">
                {
                    selectedTags.filter((item, i) => i < 15).map((selectedTag, i) => {
                        return(
                        <div role="selectedTags" key={i} className="rekomendasi-tag-active py-1 px-4 mx-3 my-2" >
                            <small role="unselectSelectedTag" className="tag-disable" onClick={() => unselectTags(selectedTag, i)}>x</small>
                            { selectedTag.name.toUpperCase() }
                        </div>
                        )
                    })
                }
                {
                    tags.filter((item, i) => i + selectedTags.length < 15).map((tag, i) => {
                        return(
                        <div role="tags" key={i} className="rekomendasi-tag py-1 px-4 mx-3 my-2" onClick={() => selectTags(tag, i)}>
                            { tag.name.toUpperCase() }
                        </div>
                        )
                    })
                }
                </div>
            </div>
            <RekomendasiModal tags={tags} selectedTags={selectedTags} getRecommended={props.getRecommended} selectTags={selectTags} unselectTags={unselectTags}/>
            <button className="btn btn-app-secondary" onClick={() => props.getRecommended(selectedTags)} disabled={ selectedTags.length === 0 ? 'disabled' : '' }
            > PILIH </button>
        </div>
    </>);
}

export default RekomendasiUKM;