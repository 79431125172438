import { useEffect, useState } from 'react';
import HtmlParser from 'react-html-parser';
import '../../css/detail-ukm/PrestasiUKM.css';
import { server } from '../../helpers/server';

const PrestasiUKM = (props) => {
    const [galleries, setGalleries] = useState(null);

    useEffect(() => {
        let toBeInserted = {};
        for(const gallery of props.galleries) {
            toBeInserted = { ...toBeInserted, [gallery.id]: gallery }
        }

        setGalleries(toBeInserted);
    }, []);

    if(!galleries) return <div></div>

    return (<>
        <h4 className="heading-app"> ACHIEVEMENTS </h4>
        <div className="row">
            {
                props.achievements.map((achievement, index) => {
                    return(
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="card ukm-prestasi-card">
                                <div className="ukm-prestasi-img d-flex justify-content-center align-items-center">
                                    {/* <h5 className="ukm-prestasi-posisi text-center">{`${achievement.name} ${achievement.position}`}</h5> */}
                                    <img src={`${server}${galleries[achievement.gallery_id].name}`} className="card-img-top" />
                                </div>
                                <div className="card-body text-center">
                                    <h5 className="card-title">{`${achievement.name} ${achievement.position}`}</h5>
                                    <p className="card-text">{HtmlParser(achievement.desc)}</p>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    </>);
}

export default PrestasiUKM;