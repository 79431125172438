import HasilSearch from '../components/hasil-search/HasilSearch.js';
import PreLoader from '../components/layouts/PreLoader.js';
import { useEffect, useState } from 'react';
import { server } from '../helpers/server';

const SearchView = ({triggerKategori}) => {
    const searchAddress = window.location.search;
    const params = new URLSearchParams(searchAddress);
    const search = params.get('search'); //bar
    const [ hasil, setHasil ] = useState();
    const [ loading, setLoading ] = useState(true);
    
    useEffect (() => {
        setLoading(true);
        fetch(`${server}api/v1/search/${search}?token=${process.env.REACT_APP_API_TOKEN}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then(res => res.json())
        .then(hasil => {
            setHasil(hasil);
            console.log(hasil);
            setLoading(false);
        })
    }, [triggerKategori]);

    if(loading) 
        return <PreLoader />

    return (<>
        <div className="container my-4">
            <HasilSearch hasil={hasil}/>
        </div>
    </>)
}

export default SearchView;