import FormSaran from '../components/form-saran/FormSaran.js';

const SaranUKM = () => {
    return (<>
        <div className="container my-4">
            <FormSaran />
        </div>
    </>)
}

export default SaranUKM;