import '../../css/detail-ukm/KontakUKM.css';
import ReactHtmlParser from 'react-html-parser'; 

const KontakUKM = (props) => {
    return (<>
        <div className="d-flex flex-column">
            <div className="col d-flex align-items-center">
                <img className="ukm-kontak-icon" src={`https://i.postimg.cc/mgLz2h8r/whatsapp-icon.png`}></img>
                <small className="ml-3 kontak-konten">{ ReactHtmlParser(props.ukm.phone_number) }</small>
            </div>
            <div className="my-2 col d-flex align-items-center">
                <img className="ukm-kontak-icon" src={`https://i.postimg.cc/yYz3tLRc/instagram-icon.png`}></img>
                <small className="ml-3 kontak-konten">{ ReactHtmlParser(props.ukm.ig) }</small>
            </div>
            <div className="col d-flex align-items-center">
                <img className="ukm-kontak-icon" src={`https://i.postimg.cc/dtmZYGRX/line-icon.png`}></img>
                <small className="ml-3 kontak-konten">{ ReactHtmlParser(props.ukm.line) }</small>
            </div>
        </div>
    </>);
}

export default KontakUKM;