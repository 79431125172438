import { useEffect, useRef, useState } from 'react';
import { Form, Nav, Navbar } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import '../../css/layouts/NavigationBar.css';
import { server } from '../../helpers/server';

const NavigationBar = (props) => {
  const [ menuKategori, setMenuKategori ] = useState();
  const [ search, setSearch ] = useState('');
  const [error, setError] = useState();
  const hapus = useRef(null);

  useEffect (() => {
    fetch (`${server}api/v1/categories?token=${process.env.REACT_APP_API_TOKEN}`)
    .then (response => response.json())
    .then (menuKategori => {setMenuKategori(menuKategori)})
  }, []);

const history = useHistory();

const searchHandler = (e) => {
  e.preventDefault();
  triggerChange(e);
  history.push(`/hasilpencarian?search=${search}`);
  hapus.current.value="";
}

const triggerChange = (e) => {
  if(props.triggerKategori)
    props.setTriggerKategori(false)
  else
    props.setTriggerKategori(true)
}

  return (<>
      <Navbar className="navbarClass" bg="navbar" sticky="top" expand="lg" collapseOnSelect>
        <div className="leftSide">
          <Nav className="leftMenuNavbar">
            <Link to="/"><div className="nav-link"><img src="https://i.postimg.cc/DZJXsZJK/LOGO.png" width='180 px' height='60px' alt="logo" /></div></Link>
          </Nav>
        </div>
          <Navbar.Toggle />
          <Navbar.Collapse>
              <Form className="searchBar ml-lg-5" onSubmit={searchHandler}>
                <input className="searchInput mr-sm-2" type="text" placeholder="Cari UKM" name="name" onChange={(e) => setSearch(e.target.value)} ref={hapus}></input>
                <button className="searchButton" type="submit"><i className="material-icons">search</i></button>
              </Form>
              <div className="rightSide d-lg-flex">
                <Nav className="menuNavbar">
                  <div className="nav-item show dropdown menuDropdown">
                    <a aria-haspopup="true" aria-expanded="true" href="#" className="dropdown-toggle nav-link" role="button">Kategori UKM</a>
                    <div aria-labelledby="" className="dropdown-menu" style= {{margin: "0px"}}>
                      {menuKategori?.data.map((data, i) => {
                        return(<Link onClick={triggerChange} to={`/kategori/${data.id}/${data.name}`}><div className="dropdown-item menuDropdown-item">{ data.name }</div></Link>)
                      })
                      }
                    </div>
                  </div>
                  <Link to="/SaranUKM" className="nav-link">Saran UKM</Link>
                  <Nav.Link onClick={props.scrollToFooter}>Tentang Kami</Nav.Link>
                </Nav>
              </div>
          </Navbar.Collapse>
      </Navbar>
  </>);
}
export default NavigationBar;