import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import '../../css/landing/RekomendasiModal.css';


const RekomendasiModal = (props) => {
    const [show, setShow] = useState(false);
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (<>
        <small className="align-self-md-end align-self-center see-more-btn" onClick={handleShow}>see more..</small>
        <Modal show={show} onHide={handleClose} className="rekomendasi-modal" centered>
            <Modal.Header className="rekomendasi-modal-header justify-content-center" closeButton>
                <div>
                    <h4 className="rekomendasi-heading text-center">Masih bingung memilih UKM ?</h4>
                    <p className="rekomendasi-subheading text-center">Yuk, pilih kata kunci yang sesuai dengan keinginanmu!</p>
                </div>
            </Modal.Header>
            <Modal.Body>
            <div>
                <div className="d-flex justify-content-center align-items-start row rekomendasi-tags-body">
                {
                    
                    props.selectedTags.map((selectedTag, i) => {
                        return(
                        <div key={i} className="rekomendasi-tag-active py-1 px-4 mx-3 my-2" >
                            <small className="tag-disable" onClick={() => props.unselectTags(selectedTag, i)}>x</small>
                            { selectedTag.name.toUpperCase() }
                        </div>
                        )
                    })
                }
                {
                    
                    props.tags.map((tag, i) => {
                        return(
                        <div key={i} className="rekomendasi-tag py-1 px-4 mx-3 my-2" onClick={() => props.selectTags(tag, i)}>
                            { tag.name.toUpperCase() }
                        </div>
                        )
                    })
                }
                </div>
            </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-app-secondary mx-auto" onClick={() => { 
                    props.getRecommended(props.selectedTags); 
                    handleClose() 
                }}
                disabled={ props.selectedTags.length === 0 ? 'disabled' : '' }> PILIH </button>
            </Modal.Footer>
        </Modal>
    </>)
}

export default RekomendasiModal