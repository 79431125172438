import '../../css/form-saran/formSaran.css';
import { useState } from 'react';
import { server } from '../../helpers/server';
import { Redirect } from 'react-router';
import SuccessToast from '../layouts/SuccessToast';
import Swal from 'sweetalert2';  

const FormSaran = () => {
    const [showSuccess, setShowSuccess] = useState(false);
    const [error, setError] = useState();

    const [input, setInput] = useState({
        name: '',
        nrp: '',
        major: '',
        suggest: '',
        reason: ''
    });
    
    var nrpField = document.querySelector("input[name=nrp]");
    if(nrpField){
        nrpField.addEventListener("invalid", function(){
            this.setCustomValidity('');
            if (!this.validity.valid) {
                this.setCustomValidity('NRP berisi angka yang berjumlah 10 digit');  
            }
        })
    };    

    const handleInput = (e) => {
        const key = e.target.name;
        const value = e.target.value;
        setInput({...input, [key]:value});
    }

    // const saveInput = (e) => {
    const submitForm = (e) => {
        e.preventDefault();

        const { name, nrp, major, suggest, reason } = input;
        fetch(`${server}api/v1/suggestions?token=${process.env.REACT_APP_API_TOKEN}&name=${name}&nrp=${nrp}&major=${major}&suggest=${suggest}&reason=${reason}`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        .then(res => res.json())
        .then(data => {
            if(data.type === 'success') { 
                document.getElementById("saranUKMForm").reset();
                setError(null);
                Swal.fire({
                    icon: 'success',
                    title: 'Saran nama ukm berhasil diajukan!',
                    showConfirmButton: false,
                    timer: 1500
                });
            } else {
                setError(data.message)
            }
        })
    }

    return(<>
        <SuccessToast text={'Saran Nama UKM Berhasil Diajukan!'} showSuccess={showSuccess} setShowSuccess={setShowSuccess}/>
        <div className="formulirSaran">
            <h4 className="captionJudul">KAMU BELUM MENEMUKAN UKM YANG SESUAI DENGAN MINAT MU?</h4>
            <h6 className="subCaption">Yuk, beri kami saran dengan mengisi form di bawah ini!</h6>
            <h4 className="judulForm pt-5 mb-5">FORM SARAN UKM</h4>
            <form className="formInputSaran" id="saranUKMForm" onSubmit={submitForm}>
                <div className="d-flex flex-column flex-lg-row mx-5 my-3">
                    <label htmlFor="nameInput" className="form-label col-4">Nama</label>
                    <input id="nameInput"  type="text" className="form-control formSaran" name="name" onChange={handleInput} required></input>
                </div>
                <div className="d-flex flex-column flex-lg-row mx-5 my-3">
                    <label htmlFor="nrpInput" className="form-label col-4">NRP</label>
                    <input id="nrpInput"  type="text" className="form-control formSaran" name="nrp" pattern="[0-9]{10}" onChange={handleInput} required></input>
                </div>
                <div className="d-flex flex-column flex-lg-row mx-5 my-3">
                    <label htmlFor="majorInput" className="form-label col-4">Jurusan</label>
                    <input id="majorInput"  type="text" className="form-control formSaran" name="major" onChange={handleInput} required></input>
                </div>
                <div className="d-flex flex-column flex-lg-row mx-5 my-3">
                    <label htmlFor="nameSuggestionInput" className="form-label col-lg-4">Saran Nama UKM</label>
                    <input id="nameSuggestionInput"  type="text" className="form-control formSaran" name="suggest" onChange={handleInput} required></input>
                </div>
                <div className="d-flex flex-column flex-lg-row mx-5 my-3">
                    <label htmlFor="reasonInput" className="form-label col-lg-4">Alasan Mengajukan UKM</label>
                    <textarea id="reasonInput" type="text" className="form-control formSaran" name="reason" onChange={handleInput} required></textarea>
                </div>
                <div className="mr-5 d-flex justify-content-end mt-5">
                    <button className="btn btn-app kirimSaran " type="submit">KIRIM</button>
                </div>
            </form>
        </div>
    </>)
}

export default FormSaran;