import PapanPengumuman from '../components/landing/PapanPengumuman.js';
import KategoriLanding from '../components/landing/KategoriLanding.js';
import RekomendasiUKM from '../components/landing/RekomendasiUKM.js';
import UKMHasilRekomendasi from '../components/landing/UKMHasilRekomendasi.js';
import { server } from '../helpers/server';
import { useEffect, useState } from 'react';
import Spinner from '../components/layouts/Spinner.js';
import Zoom from 'react-reveal/Zoom';
import SaranBanner from '../components/layouts/SaranBanner.js';

const Landing = () => {
    const [recommendedUKM, setRecommendedUKM] = useState(null);
    const [recommendedUKMLoading, setRecommendedUKMLoading] = useState(false);
    
    useEffect(() => {
        const rekomendasiUKM = JSON.parse(localStorage.getItem('rekomendasiUKM'));
        if(rekomendasiUKM) {
            setRecommendedUKM(rekomendasiUKM);
        }
    }, []);

    const getRecommended = (selectedTags) => {
        setRecommendedUKMLoading(true);
        const tagsId = selectedTags.map(tag => tag.id); 

        fetch(`${server}api/v1/select/tags?token=${process.env.REACT_APP_API_TOKEN}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ tag: tagsId })
        })
        .then(res => res.json())
        .then(data => {
            let dataObject = {};
            setRecommendedUKMLoading(false);
            data.data.map(ukmdata => {
                dataObject[ukmdata.id] = ukmdata;
            });

            data.data = dataObject;

            localStorage.setItem('rekomendasiUKM', JSON.stringify(data));
            setRecommendedUKM(data); 
        });
    }

    return(<>
        <div className="container-fluid p-0">
            <PapanPengumuman />
            {
            <div className="content-margin d-flex justify-content-center">
                <Zoom>
                    <RekomendasiUKM getRecommended={getRecommended} />
                </Zoom>
            </div>
            }
            {
                recommendedUKMLoading
                    ?
                    <Spinner />
                    :
                    recommendedUKM &&
                    <div className="content-margin">
                        <h2 className="text-center mb-4">Berikut hasil rekomendasi UKM yang dapat kamu coba!</h2>
                        <UKMHasilRekomendasi recommendedUKM={recommendedUKM}/>
                    </div>
            }  
            <div className="content-margin">
                <KategoriLanding />
            </div>
            <div className="my-5">
                <SaranBanner />
            </div>
        </div>
    </>);

}
export default Landing;